import React from "react";
import { StyledContainer } from "../../../assets/styles/WebsiteLinks";
import { Typography } from "@mui/material";


function TermsConditions() {
	return (
	  <StyledContainer>
		<Typography variant="h3" className="centered">
		  Terms and Conditions
		</Typography>
		<Typography variant="p">
		  Welcome to our Emed4u Telemedicine Platform. Our Platform is operated
		  through our website, www.emed4u.com, Emed4U Pvt Ltd., having its
		  registered office at Shiv Nagar colony Islamabad, Amritsar, Punjab,
		  India
		  <br />
		  <br />
		  Emed4U Pvt Ltd., (referred to as Emed4U) is engaged in the business of
		  providing Primary Healthcare through Emed4U Telemedicine/E-Clinic
		  Platform at your Fingertips and ease with convenience of Website{" "}
		  <a href="https://emed4u.com">https://emed4u.com/</a>.
		  <br />
		  <br />
		  The following terms and conditions govern all the transactions with
		  respect to medical consultation and advice carried out through
		  Telemedicine/E-Consultation Platform including Online referrals and
		  Health Queries, Holistic Health Information.
		  <br />
		  <br />
		  The medical consultation services available on the Emed4u Platform does
		  not in any way constitute an invitation or recommendation to avail the
		  online or tele-consultation Services.
		  <br />
		  <br />
		  The services provided through the Emed4u Telemedicine Platform are not
		  intended in any ways to be a substitute for Visiting an existing Medical
		  Registered clinic, Multi-speciality Hospital or a face to face
		  consultation with a doctor.
		  <br />
		  <br />
		  Emed4u users have to make independent, individual assessment in respect
		  of its accuracy or usefulness and personal suitability prior to making
		  any decision in reliance hereof. Terms &#39;you&#39;, &#39;your&#39;
		  ‘user’ and ‘patient’ hereafter refers to the user both registered and
		  unregistered who is using/browsing through the Emed4U website
		  www.emed4u.com of the services from Emed4u or Consulting.
		  <br />
		  <br />
		  Emed4u Pvt Ltd. is only an online intermediary as defined under the
		  Information Technology Act, 2000 and the rules thereunder and provides
		  Telemedicine platform/e-clinic/ online health solution. Our third party
		  registered medical practitioners (physicians and specialists and
		  Experts), who deliver healthcare services through Emed4u Telemedicine
		  Platform are independent persons and are responsible for their diagnosis
		  and the treatment offer to the users. Emed4u is neither responsible nor
		  concerned with the treatment or the result arising out of such treatment
		  provided to the users.
		  <br />
		  <br />
		  In addition, there may be additional terms ( patient consent letter
		  etc.) which will govern the use of the Emed4u platform. The features /
		  services of Emed4u platform are available only in India. However
		  Nonregistered Users outside India may browse through the information
		  provided in the Website. The services offered through Emed4u Platform
		  are exclusively for the registered users who pay the membership fee.
		  Emed4u does not endorse or influence control over any particular branch
		  of medicine, theory, opinion, viewpoint or position on any topic.
		</Typography>
		<Typography variant="p">
	   <strong>{`Applicability of Terms`}</strong><br />
        Please carefully go through these terms and conditions (“Terms”) and the
        Privacy policy available at www.emed4u.com (“Privacy Policy”) before you
        decide to access the Website or avail the services made available on the
        Website by Emed4u. These Terms and the Privacy Policy together
        constitute a legal agreement (“Agreement”) between you and Emed4u in
        connection with your visit to the Website and your use of the Services.
        <br />
		<br />
        The Agreement applies to you whether you are -
        <br />
		<br />
        <ul>
          <li>
            A medical practitioner or health care provider (whether an
            individual professional or an organization) or similar institution
            wishing to be listed, or already listed, on the Website, including
            designated, authorized associates of such practitioners or
            institutions (“Practitioner(s)”, “you” or “User”); or
          </li>
          <li>
            A patient, his/her representatives or affiliates, searching for
            Practitioners through the Website (“End-User”, “you” or “User”); or
          </li>
          <li>
            Otherwise a user of the Website (“you” or “User”). 
			</li>
			<br />
			This Agreement applies to those services made available by Emed4u on the Website,
            which are offered to the Users (“Services”), including the
            following:
			<br />
			<br />
          <li>For Practitioners:</li>
          <li>For other Users:</li>
        </ul>
        The Agreement will apply to your visit to and your use of the Website to
        avail the Service, as well as to all information provided by you on the
        Website at any given point in time.
        <br />
        <br />
        By downloading or accessing the Website to use the Services, you
        irrevocably accept all the conditions stipulated in this Agreement, the
        Subscription Terms of Service and Privacy Policy, as available on the
        Website, and agree to abide by them. This Agreement supersedes all
        previous oral and written terms and conditions (if any) communicated to
        you relating to your use of the Website to avail the Services. By
        availing any Service, you signify your acceptance of the terms of this
        Agreement.
        <br />
        <br />
        Your access to use of the Website and the Services will be solely at the
        discretion of Emed4u.
        <br />
        <br />
        This Agreement defines the terms and conditions under which you are
        allowed to use the Website and describes the manner in which we shall
        treat your account while you are registered as a member with us.
        <br />
        By downloading or accessing the Website to use the Services, you
        irrevocably accept all the conditions stipulated in this Agreement, the {" "}
        <strong>Subscription Terms of Service</strong> and {" "}
        <strong>Privacy Policy</strong> , as available on the Website, and agree
        to abide by them. This Agreement supersedes all previous oral and
        written terms and conditions (if any) communicated to you relating to
        your use of the Website to avail the Services. By availing any Service,
        you signify your acceptance of the terms of this Agreement.
        <br></br>
        Your access to use of the Website and the Services will be solely at the
        discretion of Emed4u.
        <br />
        <br />
        The Agreement is published in compliance of, and is governed by the
        provisions of Indian law, including but not limited to:
        <br />
        <br />
        <ul>
          <li>the Indian Contract Act, 1872,</li>
          <li>the (Indian) Information Technology Act, 2000, and</li>
          <li>
            the rules, regulations, guidelines and clarifications framed there
            under, including the (Indian) Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal
            Information) Rules, 2011 (the “SPI Rules”), and the (Indian)
            Information Technology (Intermediaries Guidelines) Rules, 2011 (the
            “IG Rules”).
          </li>
        </ul>
      </Typography>
      <Typography variant="h6"><strong>{`BOOK APPOINTMENT AND CALL FACILITY`}</strong></Typography>
      <Typography variant="p">
        Emed4u enables Users to connect with Practitioners through two methods:
        <ul>
          <li>
            Book facility that allows Users book an appointment through the
            Website;
          </li>
          <li>
            Value added telephonic services which connect Users directly to the
            Practitioner’s number provided on the Website.
          </li>
        </ul>
        Emed4u will ensure Users are provided confirmed appointment on the Book
        facility. However, Emed4u has no liability if such an appointment is
        later cancelled by the Practitioner, or the same Practitioner is not
        available for appointment. Provided, it does not fall under the heads
        listed under the Emed4u Guarantee Program, in which case the terms of
        this program shall apply.
        <br />
        <br />
        If a User has utilized the telephonic services, Emed4u reserves the
        right to share the information provided by the User with the
        Practitioner and store such information and/or conversation of the User
        with the Practitioner, in accordance with our Privacy Policy.
        <br />
        <br />
        The results of any search Users perform on the Website for Practitioners
        should not be construed as an endorsement by Emed4u of any such
        particular Practitioner. If the User decides to engage with a
        Practitioner to seek medical services, the User shall be doing so at
        his/her own risk.
        <br />
        <br />
        Without prejudice to the generality of the above, Emed4u is not involved
        in providing any healthcare or medical advice or diagnosis and hence is
        not responsible for any interactions between User and the Practitioner.
        User understands and agrees that Emed4u will not be liable for:
        <ul>
          <li>
            User interactions and associated issues User has with the
            Practitioner;
          </li>
          <li>
            the ability or intent of the Practitioner(s) or the lack of it, in
            fulfilling their obligations towards Users;
          </li>
          <li>
            any wrong medication or quality of treatment being given by the
            Practitioner(s), or any medical negligence on part of the
            Practitioner(s);
          </li>
          <li>
            inappropriate treatment, or similar difficulties or any type of
            inconvenience suffered by the User due to a failure on the part of
            the Practitioner to provide agreed Services;
          </li>
          <li>
            any misconduct or inappropriate behaviour by the Practitioner or the
            Practitioner’s staff;
          </li>
          <li>
            cancellation or no show by the Practitioner or rescheduling of
            booked appointment or any variation in the fees charged, provided
            these have been addressed to under,{" "}
            <strong>Emed4u Guarantee Program</strong>.
          </li>
        </ul>
        Users are allowed to provide feedback about their experiences with the
        Practitioner, however, the User shall ensure that, the same is provided
        in accordance with applicable law. User however understands that, Emed4u
        shall not be obliged to act in such manner as may be required to give
        effect to the content of Users feedback, such as suggestions for
        delisting of a particular Practitioner from the Website.
        <br />
        <br />
        <strong>Cancellation and Refund Policy</strong>
        <br />
        In the event that, the Practitioner with whom User has booked a paid
        appointment via the Website, has not been able to meet the User, User
        will need to write to us at support@Emed4u.com within five (5) days from
        the occurrence of such event; in which case, the entire consultation
        amount as mentioned on the Website will be refunded to the User within
        the next five (5) to six (6) business days in the original mode of
        payment done by the User while booking.
        <br />
        <br />
        Users will not be entitled for any refunds in cases where, the
        Practitioner is unable to meet the User at the exact time of the
        scheduled appointment time and the User is required to wait,
        irrespective of the fact whether the User is required to wait or choose
        to not obtain the medical services from the said Practitioner.
        <br />
        <br />
        <strong>Ownership of The Site and Related Materials</strong>
        <br />
        All pages within this Site and any material made available for download
        are the property of Emed4u, or its licensors or suppliers, as
        applicable. The Site is protected by Indian copyright and trademark
        laws.
        <strong>Electronic Communications</strong>
        <br />
        When you use any Emed4u Service, or send e-mails, text messages, and
        other communications from your desktop or mobile device to us, you are
        communicating with us electronically. You consent to receive
        communications from us electronically. You agree that (a) all agreements
        and consents can be signed electronically and (b) all notices,
        disclosures, and other communications that we provide to you
        electronically satisfy any legal requirement that such notices and other
        communications be in writing.
        <br /> <br />
        <strong>Electronic Communications</strong>
        <br />
        When you use any Emed4u Service, or send e-mails, text messages, and
        other communications from your desktop or mobile device to us, you are
        communicating with us electronically. You consent to receive
        communications from us electronically. You agree that (a) all agreements
        and consents can be signed electronically and (b) all notices,
        disclosures, and other communications that we provide to you
        electronically satisfy any legal requirement that such notices and other
        communications be in writing.
        <br />
        <br />
        <strong>Membership & Membership Terms</strong>
        <br />
        Any individual person is entitled to access products and services
        offered by Emed4u Platform on Pre-consultation or subscription of
        services offered by Emed4u Pvt Ltd. These services may not be available
        in all circumstances and any quoted prices are subject to change without
        notice.
        <br />
        <br />
        Emed4u Pvt Ltd. reserves its right to suspend, either temporarily or
        permanently, any or some services or features of those services without
        prior notice to you at its sole discussion. Emed4u Pvt Ltd. reserves its
        right to suspend all or certain aspects of the services in certain
        geographical areas at our sole discussion without prior notice. In no
        event, Emed4u will not take responsibility for the services and products
        sold to you by third party service Providers.
        <br />
        <br />
        Your membership is effective immediately upon your enrolment with the
        Emed4u and shall continue as per the subscription you have chosen until
        the end of the subscription or cancelled by Service Providers. You are
        at liberty to cancel your membership by 30 days’ notice us via E-mail at
        support@Emed4u.com or by phone to +91 1234567890.
        <br />
        <br />
        If you create a subscriber account for the Emed4u Site, you agree to
        complete the registration process by providing current, complete, and
        accurate information as required by Emed4u. You are responsible for all
        activities that occur under your account. In the event access to the
        Site or a portion thereof is limited requiring a user ID and password
        (“Protected Areas”), you agree to access Protected Areas using only your
        user ID and password as provided to you by Emed4u. You agree to protect
        the confidentiality of your user ID and password, and not to share or
        disclose your user ID or password to any third party. You agree that you
        are fully responsible for all activity occurring under your user ID.
        Your access to the Site may be revoked by Emed4u at any time with or
        without cause.
        <br />
        <br />
        You may not use any scraper, crawler, spider, robot or other automated
        means of any kind to access or copy data on the Site, deep-link to any
        feature or content on the Site, bypass our robot exclusion headers or
        other measures we may use to prevent or restrict access to the Site.
        <br />
        <br />
        Violations of system or network security may result in civil or criminal
        liability. Emed4u will investigate occurrences that may involve such
        violations and may involve, and cooperate with, law enforcement
        authorities in prosecuting users who are involved in such violations.
        You agree not to use any device, software or routine to interfere or
        attempt to interfere with the proper working of this Site or any
        activity being conducted on this Site.
        <br />
        <br />
        <strong>Payment of Onetime / Consultation Fee/Membership Fee</strong>
        <br />
        The payment of your membership fee is made either automatically by a
        charge to your account or paid in advance as per the subscription plan
        chosen by you. Except to the extent the applicable law explicitly
        requires a longer notice period, we and service provider/s reserve the
        right to terminate your membership at any time without notice including
        in the event that we/service provider are unable to provide the services
        or bill the membership to your account.
        <br />
        <br />
        <strong>Your account and registration obligations</strong>
        <br />
        By using Emed4u telemedicine Platform, you shall maintain
        confidentiality and secure your login details of your account including
        display name and password. You will be solely responsible for all the
        activities that may happen in your name and registered membership. You
        are responsible for providing accurate and correct information
        pertaining to you and for updating of such information or any
        modifications of such information. If you know or have any reason to
        believe that your account is being used in an unauthorised manner, you
        should inform us immediately.
        <br />
        <br />
        The Emed4u Platform and the services offered by us, our service
        providers or partners or affiliates are not for use for medical
        emergencies or secondary and tertiary care situations. If you believe
        there is an emergency or that someone needs immediate medical
        assistance, any other emergency service providers for medical help. This
        Website and any services offered are not intended to be used in
        emergency circumstances. Your interaction with any services provided
        through our Telemedicine Platform is not intended to replace your
        relationship with your existing primary care physician or physical
        examination. Physicians will recommend for physical examination or
        diagnostic tests in certain circumstances. You need to either visit
        polyclinics or associate polyclinics/hospitals situated near your
        location or any other suggested primary healthcare provider and
        diagnostic labs for physical examination and for providing diagnostic
        test reports.
        <br />
        <br />
        If you submit, upload, post or transmit any health information, medical
        history, conditions, problems, symptoms, personal information, consent
        forms, agreements, requests, comments, ideas, suggestions, information,
        files, videos, images or other materials to us or our Site (“User
        Information”), you agree not to provide any User Information that (1) is
        false, inaccurate, defamatory, abusive, libelous, unlawful, obscene,
        threatening, harassing, fraudulent, pornographic, or harmful, or that
        could encourage criminal or unethical behavior, (2) violates or
        infringes the privacy, copyright, trademark, trade dress, trade secrets
        or intellectual property rights of any person or entity, or (3) contains
        or transmits a virus or any other harmful component. You agree not to
        contact other site users through unsolicited e- mail, telephone calls,
        mailings or any other method of communication. You represent and warrant
        to Emed4u and its Providers that you have the legal right and
        authorization to provide all User Information to Emed4u and its
        Providers for use as set forth herein and required by Emed4u and the
        Provider.
        <br />
        <br />
        <strong>Privacy and Confidentiality</strong>
        <br />
        Emed4u hereby confirms and agree that it will not share the details of
        its patients or any information related to their medical record includes
        the details of a person’s family history, genetic testing, history of
        diseases and treatments etc which is gathered in the course of treatment
        nor can the doctor disclose to anyone else the mode of treatment or the
        advice given by him to the patient. Company and its Doctor can only
        disclose the patient’s information only in a court of law under orders
        of the Presiding Judge.
        <br />
        <br />
        <strong>Refund Policy</strong>
        <br />
        Emed4u will encourage all users/customers support for any refund related
        queries. Please get in touch with – …… 0091- Tel. or email us at –
        info@Emed4U.com
        <br />
        <br />
        <strong>Individual Medical Profile/ Medical Information</strong>
        <br />
        This website may contain or constantly Update general information
        relating to various medical conditions and their treatment. Such
        information is provided for informational purposes and General Awareness
        only. The content of this website is not intended to be a substitute for
        professional medical advice, diagnosis, or treatment. The content
        relating to medical conditions and their treatment is not meant to be a
        substitute for advice provided by a doctor or other qualified healthcare
        professionals. You should not disregard or delay seeking medical advice
        based on anything that appears in this website.
        <br />
        <br />
        <strong>Links to third party websites</strong>
        <br />
        You may be provided with links on the Websites that direct you to third
        party websites/ applications/ content or service providers, including
        advertisers and e-commerce websites (collectively "Third Party
        Websites"). Emed4u does not endorse any Third-Party Websites that You
        may be directed to from the Website. Links to such Third-Party Websites
        are provided for your convenience only. Please exercise your independent
        judgment and prudence when visiting / using any Third-Party Websites. If
        you decide to click on the links to visit such Third-Party website, you
        do so of your own volition. Your usage of such Third- Party Websites
        and/or use of any content available on such Websites is subject to the
        terms of use of the respective Third-Party Website and Emed4U is not
        responsible for your use of any Third-Party Websites.
        <br />
        <br />
        <strong>Disclaimer of Warranties</strong>
        <br />
        Emwd4u telemedicine/ E-medicine platform and the services are provided
        “as is,” with all faults, with no representations or warranties of any
        kind, either expressed or implied, including, but not limited to, the
        implied warranties of merchantability, fitness for a purpose, and
        non-infringement. You assume total responsibility and risk for your use
        of the Emed4u platform and the services, and hyperlinked websites. No
        oral or written information or advice given by either Emed4u
        telemedicine nor its authorized representatives shall create a warranty
        nor in any way increase the scope of this warranty.
        <br />
        <br />
        <strong>Limitation of Liability</strong>
        <br />
        Emed4u is not responsible nor liable for any direct, indirect,
        incidental, consequential, special, exemplary, punitive, or other
        damages (including, without limitation, those resulting from any loss)
        arising out of or relating in any way to the DocOnline telemedicine
        platform and the services and/or any hyperlinked website, whether based
        on warranty, contract, tort, or any other legal theory and whether or
        not advised of the possibility of such damages. Your sole remedy for
        dissatisfaction with the Emed4u telemedicine platform, Emed4u
        telemedicine platform related services, and/or hyperlinked websites is
        to stop using the Emed4u platform and/or those services. Emed4u
        liability for any of its or associate’s direct or indirect or
        consequential acts/ omissions/ commissions be restricted to the maximum
        extent of INR 1000/- and nothing further.
        <br />
        <br />
        <strong>Force Majeure</strong>
        <br />
        We will not be deemed to be in breach of these terms or liable for any
        breach of these terms or our privacy policy due to any event or
        occurrence beyond our reasonable control, including without limitation,
        acts of God, terrorism, war, invasion, failures of any public networks,
        electrical shortages, earthquakes or floods, civil disorder, strikes,
        fire or other disaster.
        <br />
        <br />
        <strong>Disclaimer of Warranties</strong>
        <br />
        EMED4U DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE
        UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE
        CORRECTED. THIS SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED
        WITHIN IT OR ANY SITE-RELATED SERVICE, IS PROVIDED “AS IS,” WITH ALL
        FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER
        EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY
        OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. EMED4U DOES
        NOT WARRANT THE ACCURACY, COMPLETENESS OR TIMELINESS OF THE INFORMATION
        OBTAINED THROUGH THE SITE.
        <br />
        <br />
        YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE,
        SITE-RELATED SERVICES, AND LINKED WEBSITES. EMED4U DOES NOT WARRANT THAT
        FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN
        HORSES OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR
        IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA BACK
        UP AND SECURITY.
        <br />
        <br />
        <strong>Indemnity</strong>
        <br />
        You agree to defend, indemnify and hold Emed4u harmless from and against
        all third party claims, damages and expenses (including reasonable
        attorneys’ fees) against or incurred by Emed4u arising out of your
        breach of these Terms of Use or violation of applicable law, or access
        by anyone accessing the Site using your user ID and password.
        Notwithstanding the foregoing or anything to the contrary herein, Emed4u
        shall only exercise its rights in this Section after addressing
        applicable claims with Your employer or health plan should you be
        accessing the Site or Services through your employer or health plan.
        <br />
        <br />
        <strong>Intellectual Property</strong>
        <br />
        Removing or altering the copyright notice on any material that appears
        in this website is strictly prohibited. Nothing contained in this
        website should be construed as granting any license or other right
        without the express written permission of Emed4u except as provided
        herein. Emed4u grants limited, revocable, non-transferable and
        non-exclusive license limited solely to viewing, accessing or
        downloading any content limited to availing the services from us for
        personal or non-commercial purposes only. All information contained in
        or accessed through this website is protected under Copyright,
        Trademark, Design, and Patent laws and your usage is limited to the
        extent of browsing or availing services by accessing our integrated
        platform and no further (“Limited Access”). Any act or attempt of using
        the software beyond the users limited access is considered as a
        violation of these Terms of Use herein and Emed4u reserves its rights to
        take appropriate action against such acts or usage. Emed4u has the
        exclusive right to transfer or assign the rights for the user to
        understand that use of the website doesn’t imply transfer or assignment
        of ownership except to use it for browsing or accessing the website for
        availing its services.
        <br />
        <br />
        <strong>Assignment</strong>
        <br />
        Usage of the website is for your limited personal purpose and is not
        transferable or assignable. However, Emed4u reserves its rights to
        assign or transfer or subcontract its obligations or rights to its
        successors, affiliates, associates or any entity through contractual
        understanding or by operation of law or acquiring all or substantially
        all the assets of Emed4u.
        <br />
        <br />
        <strong>Governing Law and Dispute Resolution</strong>
        <br />
        Emed4u will try work in good faith to resolve any issue you have with
        Site, including services ordered or purchased through the Site, if you
        bring that issue to the attention of our customer service department.
        However, we realize that there may be rare cases where we may not be
        able to resolve an issue to a customer’s satisfaction. All the
        direct/indirect expert Advices/ Answers/ Physical and Intellectual
        Products will be under the Term and conditions and the Agreements. This
        Agreement shall be governed by the laws of India, irrespective of places
        from you communicate, interact, operate, resides and transact.
        <br />
        <br />
        You agree that all claims, differences and disputes arising under or in
        connection with or in relation to this Agreement shall be subject to the
        exclusive jurisdiction of the courts at Amritsar irrespective of places
        from you communicate, interact, operate, resides and transact and you
        hereby accede to and accept the jurisdiction of such courts. This
        document is an electronic record in terms of Information Technology Act,
        2000 and rules there under as applicable and amended from time to time.
        This electronic record is generated by a computer system and does not
        require any physical or digital signatures. This document is published
        in accordance with the provisions of Rule 3 (1) of the Information
        Technology (Intermediaries guidelines) Rules, 2011 that require
        publishing the rules and regulations, privacy policy and Agreement for
        access or usage of Emed4u Telemedicine Platform.
      </Typography>
    </StyledContainer>
  );
}

export default TermsConditions;